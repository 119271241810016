import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import styles from './App.css';


import Terminal from'./Terminal/Terminal'
import About from '../Components/About/About';
import ContactMe from '../Components/ContactMe/ContactMe';
import Experience from '../Components/Experience/Experience';
import SnakeGame from '../Components/SnakeGame/SnakeGame';
import Work from '../Components/Work/Work';

let timeoutID = null;
let debug = false;
class App extends PureComponent {
  constructor(props) {
    super(props);
    this.terminal = React.createRef();
  }


  state = {
    tabs:[{name:"Terminal",displayed:true,id:0}],
    load:false,
    repos:[{name:"",link:"",description:"",languages:"",size:0}]
  };

  setLoad = () =>{
    this.setState({load:!this.state.load})
  }
  setTimeoutId = (iTimeoutID) =>{
    timeoutID = iTimeoutID;
  }
 

  addTab = (tabName) =>{
    if(timeoutID !== undefined)
      clearTimeout(timeoutID);

    let tempTabs = [...this.state.tabs];

    if(tabName !=="Terminal" && tempTabs.findIndex((tab)=>tab.name === tabName) !== -1){
      let id = tempTabs[tempTabs.findIndex((tab)=>tab.name === tabName)].id;
      this.selectTab(id);
      return false;
    }

    tempTabs.map((tab)=>tab.displayed = false);
    console.log("Adding tabName: " + tabName);
    switch(tabName){   
      case "Terminal":{
        tempTabs.push({name:"Terminal",displayed:true});
        break;
      }

      case "About":{
        tempTabs.push({name:"About",displayed:true});
        break;
      }

      case "Experience":{
        tempTabs.push({name:"Experience",displayed:true});
        break;
      }

      case "Work":{
        tempTabs.push({name:"Work",displayed:true});
        break;
      }

      case "Contact":{
        tempTabs.push({name:"Contact",displayed:true});
        break;
      }
      
      default :{
        return false;
      }
    }
    this.setState({tabs:tempTabs});
    return true;
  }

  removeTab = (id) =>{
    if(timeoutID !== null)
      clearTimeout(timeoutID);

    const tempTabs = [...this.state.tabs];
    let index = tempTabs.findIndex((tab)=>tab.displayed === true);
    tempTabs[index].displayed = false;
    tempTabs[0].displayed = true;

    if(!Number.isInteger(parseFloat(id)))
      index = tempTabs.findIndex((tab)=>tab.name === id);
    else
      index = tempTabs.findIndex((tab)=>tab.id === id);
      
    if(index <= 0)
      return false;
    
    else
      tempTabs.splice(index, 1);
      
    this.setState({tabs:tempTabs})
    
    return true;
  }

  selectTab = (id) =>{
    if(timeoutID !== null)
      clearTimeout(timeoutID);

    let tempArr = [...this.state.tabs].reverse();
    let findIndex = () =>{
      for(let i = tempArr.length - 1; i >= 0 ;i--){
        if(tempArr[i].id === id)
          return i;
      }
      return -1;
    }
    let index = findIndex();
    console.log("index " + index);


    for(let i = 0; i < tempArr.length; i++){
      if(i === index){
        tempArr[i].displayed = true;
      }
      else
        tempArr[i].displayed = false;
    }
    tempArr.reverse();
    this.setState({tabs:tempArr});
  }

  getContent = () =>{
    let index = this.state.tabs.findIndex((tab) => tab.displayed);
    console.log("Selected Index is:  " + index);
    if(this.state.tabs[index].name === 'About')
      return <About></About>
    else if(this.state.tabs[index].name === 'Experience')
      return <Experience></Experience>
    else if(this.state.tabs[index].name === 'Work')
      return <Work repos={this.state.repos} setLoad={this.setLoad} getLoad={this.state.load}></Work>
    else if(this.state.tabs[index].name === 'Contact')
      return <ContactMe></ContactMe>
    else if(this.state.tabs[index].name === 'Skills')
      return <SnakeGame></SnakeGame>
    else
      return null;

  }

  render () {
 
    let id = 0;
    let cx = classNames.bind(styles);
    const allTabs = (
      <li id="tabs">
        {
        this.state.tabs.map((tab)=>{
          let classes = cx({indTab:tab.id!==0},{terminal:tab.id===0},{selectedTab:tab.displayed});
          tab.id = id;
          let result = (<li key={id} onClick={()=>this.selectTab(tab.id)} className={classes}>{tab.name}<b onClick={(e)=> {e.stopPropagation();this.removeTab(tab.id);}} className="closeX">X</b></li>);
          id++;
          return result;  
        })}
        <li id="addTab">+</li>
      </li>
    );

   let content = this.getContent();
    
    

      return (

	  <div id="app">
      {allTabs}
      <Terminal
        ref={this.terminal}
        addTab = {this.addTab}
        removeTab = {this.removeTab}
        setTimeoutId = {this.setTimeoutId}
        display = {this.state.tabs[0].displayed ? "" : "hideTerminal"}
        inView = {this.state.tabs[0].displayed}>
      </Terminal>
	      {content}
	      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
	          <body>
	      <div class="menu">
		  <div class="label">Menu</div>
		  <div class="spacer"></div>
		  <div class="item" onClick={()=> this.terminal.current.sendCommand("exec AboutMe")}><span>About Me</span></div>
		  <div class="item" onClick={()=> this.terminal.current.sendCommand("exec Experience")}><span>Experience</span></div>
		  <div class="item" onClick={()=> this.terminal.current.sendCommand("exec Work")}><span>Work</span></div>
		  <div class="item" onClick={()=> this.terminal.current.sendCommand("exec Skills")}><span>Skills</span></div>
		  <div class="item" onClick={()=> this.terminal.current.sendCommand("exec ContactMe")}><span>Contact Me</span></div>
	      </div>



		      <canvas id="c"></canvas>

		      <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css" rel="stylesheet"/>

			  <div class="center">
			      <div id="social">
				  <ul>

				      <li class="linkedin">
					  <button class="border"><a href="https://www.linkedin.com/in/ghassen-el-kamel-01b329176/" target="_blank" rel="noreferrer"><i class="fa fa-linkedin"></i></a></button>
				      </li>

				      <li class="github">
					  <button class="border"><a href="https://github.com/GhassenElKamel" target="_blank" rel="noreferrer"><i class="fa fa-github"></i></a></button>
				      </li>

				      <li class="facebook">
					  <button class="border" ><a href="https://www.facebook.com/profile.php?id=100000609568953" target="_blank" rel="noreferrer"><i class="fa fa-facebook"></i></a></button>
				      </li>

				      <li class="instagram">
					  <button class="border"><a href="https://www.instagram.com/ze.push/" target="_blank" rel="noreferrer"><i class="fa fa-instagram"></i></a></button>
				      </li>

				  </ul>
			      </div>
			  </div>

    </body>


</div>



    );
  }
}

export default App;

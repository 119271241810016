import React, { useState, useEffect } from 'react';
import './Work.css';
import '../../Containers/Terminal/Terminal.css';

import { ReactComponent as ExternalLink } from "../../Resources/external-link.svg";
import { ReactComponent as Folder } from "../../Resources/folder.svg";

const Work = (props) => {
  const [repos, setRepos] = useState([]);

  useEffect(() => {
    const fetchRepos = async () => {
      try {
        const response = await fetch('https://api.github.com/users/ghassenelkamel/repos');
        const data = await response.json();
        setRepos(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchRepos();
  }, []);

  const redirectToRepo = (url) => {
    window.open(url, '_blank');
  };

  let content = (
    <ul>
      {repos.map((repo, index) => {
        if (!props.getLoad && index >= 6) return null;
        return (
          <li key={repo.id} onClick={() => redirectToRepo(repo.html_url)}>
            <div className="insideContainer">
              <div className="logo">
                <Folder className="folder" />
              </div>
              <div className="title">
                <p>{repo.name}</p>
              </div>
              <div className="description">
                <p>{repo.description}</p>
              </div>
              <footer>
                <p>{repo.language}</p>
              </footer>
            </div>
          </li>
        );
      })}
    </ul>
  );

  return (
    <div className="main">
      <div className="projects">
        <div className="workHeader">
          <h3>Some Things That I've Built</h3>
        </div>
        {content}
        <button onClick={props.setLoad} className="load">
          {props.getLoad ? "Load Less" : "Load More"}
        </button>
      </div>
    </div>
  );
}

export default Work;

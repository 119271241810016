import React, {useState, Fragment, useEffect, forwardRef, useImperativeHandle } from 'react';
import './Terminal.css'

const Terminal = forwardRef((props, ref) => {

    const [getTerLine,setTerLine] = useState({Timer:10,Value:"[Kagha@ArchBios~]$ ",blink:true});
    useImperativeHandle(
        ref,
        () => ({
            sendCommand(command) {
                if(getTerLine.Timer >= 0){
                    setCountDown();
                    clearTimeout(timeout)
                }


                getTerLine.Value = "[Kagha@ArchBios~]$ " + command;
                updateContent();
            }
        }),
    )


    const N = () =>{return (<Fragment><br/>⠀</Fragment>);}

    function downloadFile(filename) {
        const link = document.createElement('a');
        link.href = `https://drive.google.com/uc?export=download&id=${filename}`;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }        
      

    const starterArr = [
        (<h2>Ghassen El Kamel</h2>),
        (<h3>Linux Insprired Terminal{N()}</h3>),

        (<p>To Use the Portfolio Either <u className="attention">Use the Navigation</u> Or <u className="attention">Explore the Terminal</u></p>),
        (<p>To Begin, Type:{N()}</p>),
        (<p className="indented"><b className="I">./AboutMe</b> or <b className="I">exec AboutMe</b>: Opens about me {N()}</p>),
        (<p className="indented"><b className="I">./Experience</b> or <b className="I">exec Experience</b>: Opens my previous work experience{N()}</p>),
        (<p className="indented"><b className="I">./Work</b> or <b className="I">exec Work</b>: Opens previous projects on GitHub{N()}</p>),
        (<p className="indented"><b className="I">./Skills</b> or <b className="I">exec  Skills</b>: Runs the terminal Skills script{N()}</p>),
	    (<p className="indented"><b className="I">./ContactMe</b> or <b className="I">exec ContactMe</b>: Runs contact me program in terminal{N()}</p>),
        (<p className="indented"><b className="I">Download_CV_FR</b> or <b className="I">exec  Download_CV_FR</b>: Download my CV in FRENCH{N()}</p>),
        (<p className="indented"><b className="I">Download_CV_ENG</b> or <b className="I">exec  Download_CV_ENG</b>: Download my CV in ENGLISH{N()}</p>),
	  (<p>Use the Tab for auto suggestion and the arrows to navigate the suggested list {N()} </p> )
    ]
    let[content,setContent] = useState({
        arr:starterArr
    });
    useEffect(()=>{
       let element = document.getElementById("command-line")
        if(element !== null){
            element.scrollIntoView();
        }
    },[content.arr])

    
    const setCountDown = (num) =>{
        let tempArr = [...content.arr];

        if(num === undefined){
            tempArr[10] = (<p></p>);
        }
        else
            tempArr[10] = (<p>No User Input Detected Opening About Me Page in {num} Seconds ...{N()}</p>)

        setContent({arr:tempArr});
    }


    let [timeout,setT] = useState();
    useEffect(()=>{
        setT(setTimeout(()=>{
            setCountDown();
            getTerLine.Value = "[Kagha@ArchBios~]$ help";
            updateContent();
        },getTerLine.Timer * 1500))
    },[])
    

    const updateTerminalLine = (e) =>{
        
        if(getTerLine.Timer >= 0){
            setCountDown();
            clearTimeout(timeout)
        }

        setTerLine({Value:("[Kagha@ArchBios~]$ "  + parseString(e.target.value)),blink:true});
    }
    

    const updateContent =(e) =>{
        if(e !== undefined)
            e.preventDefault();


        parseCommand(parseString());

        setTerLine({Value:"[Kagha@ArchBios~]$ ",blink:true});
    }


    const parseString = (input)=>{
        
        let bufferIndex = 19;
        if(input !== undefined)
            return input.substring(bufferIndex);
        else
            return getTerLine.Value.substring(bufferIndex);
    }



    


    let key = 0;
    const allTerminalText = (       
        <div className={"css-typing "}>           
            {content.arr.map((item)=>{
                return <Fragment key={key++}>{item}</Fragment>;
            })}
        </div>
    )


    let element = document.getElementById("command-line")
    if(element !== null && props.inView === true){
        if(element !== document.activeElement)
            element.focus();
    }

 
    const parseCommand = (command) =>{

        let commandSelector = command.split(" ")

        let tempArr = [...content.arr];
        tempArr.push(<p>[Kagha@ArchBios~]${parseString()}</p>);

        switch(commandSelector[0]){
            case "":{
                tempArr.push(<p>{parseString()}</p>);
                break;
            }

            case "clear":{
                if(commandSelector.length <= 1){
                    starterArr[10] = (<p></p>);
                    setContent({arr:starterArr});
                    return null;
                }
                else{
                    tempArr.push(<p>[Kagha@ArchBios~]${parseString()}</p>);
                    tempArr.push(<p>Unknown Argument: {commandSelector[1]}<br/>⠀</p>);
                }
                break;
            }

            case "help":{
                if(commandSelector.length <= 1){
		    tempArr.push(<p>ㅤㅤㅤㅤ</p>);
                    tempArr.push(<p  className="indented"><b className="I">[clear]</b>: clears command window</p>);
                    tempArr.push(<p  className="indented"><b className="I">[ls]</b>: list all files</p>);
                    tempArr.push(<p  className="indented"><b className="I">[exec argument]</b>: run a specific program</p>);
                    tempArr.push(<p  className="indented"><b className="I">[./argument]</b>: open a specific document⠀</p>);
                    tempArr.push(<p  className="indented"><b className="I">[pkill argument]</b>: close a specific  program or file</p>);
		    tempArr.push(<p>ㅤㅤㅤㅤ</p>);

                }
                else{ 
                    tempArr.push(<p>Unknown Argument: {commandSelector[1]}<br/>⠀</p>);                   
                }
                
                break;
            }

            case "ls":{
                if(commandSelector.length <= 1){
		    tempArr.push(<p>ㅤㅤㅤㅤ</p>);
		    tempArr.push(<p>AboutMe    Experience    Work    Skills    ContactMe</p>);
		    tempArr.push(<p>ㅤㅤㅤㅤ</p>);
	
                }
                else{ 
                    tempArr.push(<p>Unknown Argument: {commandSelector[1]}<br/>⠀</p>);                   
                }
                break;
            }
           case "Download_CV_FR":{
                if(commandSelector.length <= 1){
		    tempArr.push(<p>ㅤㅤㅤㅤ</p>);
		    tempArr.push(<p>Downloading....</p>);
		    tempArr.push(<p>ㅤㅤㅤㅤ</p>);

		    downloadFile('1cTf3qtwUZuSSiF48BzMZ8JzahwQDFaz_');

                }
                else{
                    tempArr.push(<p>Unknown Argument: {commandSelector[1]}<br/>⠀</p>);
                }
                break;
            }
            case "Download_CV_ENG":{
                if(commandSelector.length <= 1){
		    tempArr.push(<p>ㅤㅤㅤㅤ</p>);
		    tempArr.push(<p>Downloading....</p>);
		    tempArr.push(<p>ㅤㅤㅤㅤ</p>);

		    downloadFile('1NzqTX3gn3fCMptJyJSjYLfioL6R05M34');

                }
                else{
                    tempArr.push(<p>Unknown Argument: {commandSelector[1]}<br/>⠀</p>);
                }
                break;
            }
	          case "./Skills":{
                if(commandSelector.length <= 1){
		    tempArr.push(<p>ㅤㅤㅤㅤ</p>);
		    tempArr.push(<dt>Java & C</dt>);
		    tempArr.push(          <dd>
					       ##{" "}
					       <span style={{ color: "#00DE12", textShadow: "0 0 5px #00DE12" }}>
						   #############
					       </span>{" "}
					       ##
					   </dd>);
		    tempArr.push(<p>ㅤㅤㅤㅤ</p>);
		    tempArr.push(<dt>Javascript</dt>);
		    tempArr.push(          <dd>
					       ##{" "}
					       <span style={{ color: "#42D100", textShadow: "0 0 5px #42D100" }}>
						   ###########
					       </span>
					       {"   "}
					       ##
					   </dd>);
		    tempArr.push(<p>ㅤㅤㅤㅤ</p>);
		    tempArr.push(<dt>Linux/Unix</dt>);
		    tempArr.push(          <dd>
					       ##{" "}
					       <span style={{ color: "#42D100", textShadow: "0 0 5px #42D100" }}>
						   ###########
					       </span>
					       {"   "}
					       ##
					   </dd>);

		    tempArr.push(<p>ㅤㅤㅤㅤ</p>);
		    tempArr.push(<dt>AWS & DevOps</dt>);
		    tempArr.push(          <dd>
					       ##{" "}
					       <span style={{ color: "#42D100", textShadow: "0 0 5px #42D100" }}>
						   ##########
					       </span>
					       {"   "}
					       ##
					   </dd>);

		    tempArr.push(<p>ㅤㅤㅤㅤ</p>);
		    tempArr.push(<dt>MYSQL/MongoDB</dt>);
		    tempArr.push(          <dd>
					       ##{" "}
					       <span style={{ color: "#42D100", textShadow: "0 0 5px #42D100" }}>
						   ##########
					       </span>
					       {"   "}
					       ##
					   </dd>);


		    tempArr.push(<p>ㅤㅤㅤㅤ</p>);
		    tempArr.push(<dt>C++</dt>);
		    tempArr.push(          <dd>
					       ##{" "}
					       <span style={{ color: "#99D100", textShadow: "0 0 5px #99D100" }}>
						   ########
					       </span>
					       {"      "}
					       ##
					   </dd>);
		    tempArr.push(<p>ㅤㅤㅤㅤ</p>);
		    tempArr.push(<dt>Python</dt>);
		    tempArr.push(          <dd>
					       ##{" "}
					       <span style={{ color: "#99D100", textShadow: "0 0 5px #99D100" }}>
						   ########
					       </span>
					       {"      "}
					       ##
					   </dd>);
		    tempArr.push(<p>ㅤㅤㅤㅤ</p>);
		    tempArr.push(<dt>Php</dt>);
		    tempArr.push(          <dd>
					       ##{" "}
					       <span style={{ color: "#D16200", textShadow: "0 0 5px #D16200" }}>
						   #######
					       </span>
					       {"         "}
					       ##
					   </dd>);
		    tempArr.push(<p>ㅤㅤㅤㅤ</p>);

                }
                else{
                    tempArr.push(<p>Unknown Argument: {commandSelector[1]}<br/>⠀</p>);
                }
                break;
            }

	case "./AboutMe":{
            if(props.addTab("About"))
                tempArr.push(<p>Opening File: {commandSelector[1]} ...<br/>⠀</p>);
            else
                tempArr.push(<p>File {commandSelector[1]} is already open redirecting...<br/>⠀</p>);

            break;
        }

        case "./Experience":{
            if(props.addTab("Experience"))
                tempArr.push(<p>Opening File: {commandSelector[1]} ...<br/>⠀</p>);
            else
                tempArr.push(<p>File {commandSelector[1]} is already open redirecting...<br/>⠀</p>);

            break;
        }

        case "./Work":{
            if(props.addTab("Work"))
                tempArr.push(<p>Opening File: {commandSelector[1]} ...<br/>⠀</p>);
            else
                tempArr.push(<p>File {commandSelector[1]} is already open redirecting...<br/>⠀</p>);

            break;
        }

        case "./ContactMe":{
            if(props.addTab("Contact"))
                tempArr.push(<p>Opening File: {commandSelector[1]} ...<br/>⠀</p>);
            else
                tempArr.push(<p>File {commandSelector[1]} is already open redirecting...<br/>⠀</p>);

            break;
        }

        case "./Terminal":{
            if(props.addTab("Terminal"))
                tempArr.push(<p>Opening new {commandSelector[1]} tab ...<br/>⠀</p>);
            else
                tempArr.push(<p>File {commandSelector[1]} is already open redirecting...<br/>⠀</p>);

            break;
        }

            case "exec":{

                if(commandSelector.length <= 1)
                    tempArr.push(<p>Error Expected Argument open [argument]<br/>⠀</p>);

                else{ 
                    switch(commandSelector[1]){
                        case "AboutMe":{
                            if(props.addTab("About"))
                                tempArr.push(<p>Opening File: {commandSelector[1]} ...<br/>⠀</p>);
                            else
                                tempArr.push(<p>File {commandSelector[1]} is already open redirecting...<br/>⠀</p>);
                            
                            break;
                        }

                        case "Experience":{
                            if(props.addTab("Experience"))
                                tempArr.push(<p>Opening File: {commandSelector[1]} ...<br/>⠀</p>);
                            else
                                tempArr.push(<p>File {commandSelector[1]} is already open redirecting...<br/>⠀</p>);
                            
                            break;
                        }

                        case "Work":{
                            if(props.addTab("Work"))
                                tempArr.push(<p>Opening File: {commandSelector[1]} ...<br/>⠀</p>);
                            else
                                tempArr.push(<p>File {commandSelector[1]} is already open redirecting...<br/>⠀</p>);
                            
                            break;
                        }

                        case "Download_CV_FR":{
                            
	                    	downloadFile('1cTf3qtwUZuSSiF48BzMZ8JzahwQDFaz_');
                            tempArr.push(<p>Downloading File: {commandSelector[1]} ...<br/>⠀</p>);
                            break;
                        }

                        case "Download_CV_ENG":{
                            downloadFile('1NzqTX3gn3fCMptJyJSjYLfioL6R05M34');
                            tempArr.push(<p>Downloading File: {commandSelector[1]} ...<br/>⠀</p>);
                            break;
                        }

                        case "ContactMe":{
                            if(props.addTab("Contact"))
                                tempArr.push(<p>Opening File: {commandSelector[1]} ...<br/>⠀</p>);
			    else
                                tempArr.push(<p>File {commandSelector[1]} is already open redirecting...<br/>⠀</p>);
                            
                            break;
                        }

                        case "Skills":{
		    tempArr.push(<p>ㅤㅤㅤㅤ</p>);
		    tempArr.push(<dt>Java</dt>);
		    tempArr.push(          <dd>
					       ##{" "}
					       <span style={{ color: "#00DE12", textShadow: "0 0 5px #00DE12" }}>
						   #############
					       </span>{" "}
					       ##
					   </dd>);
		    tempArr.push(<p>ㅤㅤㅤㅤ</p>);
		    tempArr.push(<dt>C</dt>);
		    tempArr.push(          <dd>
					       ##{" "}
					       <span style={{ color: "#42D100", textShadow: "0 0 5px #42D100" }}>
						   ###########
					       </span>
					       {"   "}
					       ##
					   </dd>);
		    tempArr.push(<p>ㅤㅤㅤㅤ</p>);
		    tempArr.push(<dt>Linux/Unix</dt>);
		    tempArr.push(          <dd>
					       ##{" "}
					       <span style={{ color: "#42D100", textShadow: "0 0 5px #42D100" }}>
						   ###########
					       </span>
					       {"   "}
					       ##
					   </dd>);

		    tempArr.push(<p>ㅤㅤㅤㅤ</p>);
		    tempArr.push(<dt>Javascript</dt>);
		    tempArr.push(          <dd>
					       ##{" "}
					       <span style={{ color: "#42D100", textShadow: "0 0 5px #42D100" }}>
						   ##########
					       </span>
					       {"   "}
					       ##
					   </dd>);

		    tempArr.push(<p>ㅤㅤㅤㅤ</p>);
		    tempArr.push(<dt>SQL</dt>);
		    tempArr.push(          <dd>
					       ##{" "}
					       <span style={{ color: "#42D100", textShadow: "0 0 5px #42D100" }}>
						   ##########
					       </span>
					       {"   "}
					       ##
					   </dd>);


		    tempArr.push(<p>ㅤㅤㅤㅤ</p>);
		    tempArr.push(<dt>C++</dt>);
		    tempArr.push(          <dd>
					       ##{" "}
					       <span style={{ color: "#99D100", textShadow: "0 0 5px #99D100" }}>
						   ########
					       </span>
					       {"      "}
					       ##
					   </dd>);
		    tempArr.push(<p>ㅤㅤㅤㅤ</p>);
		    tempArr.push(<dt>Python</dt>);
		    tempArr.push(          <dd>
					       ##{" "}
					       <span style={{ color: "#99D100", textShadow: "0 0 5px #99D100" }}>
						   ########
					       </span>
					       {"      "}
					       ##
					   </dd>);
		    tempArr.push(<p>ㅤㅤㅤㅤ</p>);
		    tempArr.push(<dt>Php</dt>);
		    tempArr.push(          <dd>
					       ##{" "}
					       <span style={{ color: "#D16200", textShadow: "0 0 5px #D16200" }}>
						   #######
					       </span>
					       {"         "}
					       ##
					   </dd>);
			    tempArr.push(<p>ㅤㅤㅤㅤ</p>);

                        }
			                break;

                    }

                                       
                }
                break;
            }

            case "pkill":{
                let argument = commandSelector[1];

                if(commandSelector.length <= 1)
                    tempArr.push(<p>Error Expected Argument close [argument]<br/>⠀</p>);

                else{ 

                    switch(argument){
                        case "AboutMe":{
                            if(props.removeTab("About"))
                                tempArr.push(<p>aboutMe has been closed<br/>⠀</p>);
                            else
                                tempArr.push(<p>aboutMe is not open<br/>⠀</p>);
                            break;
                        }

                        case "Experience":{
                            if(props.removeTab("Experience"))
                                tempArr.push(<p>experience has been closed<br/>⠀</p>);
                            else
                                tempArr.push(<p>experence is not open<br/>⠀</p>);
                            break;
                        }

                        case "Work":{
                            if(props.removeTab("Work"))
                                tempArr.push(<p>work has been closed<br/>⠀</p>);
                            else
                                tempArr.push(<p>work is not open<br/>⠀</p>);
                            break;
                        }

                        case "ContactMe":{
                            if(props.removeTab("Contact"))
                                tempArr.push(<p>contactMe has been closed<br/>⠀</p>);
                            else
                                tempArr.push(<p>contactMe is not open<br/>⠀</p>);
                            break;
                        }

                        default:{
                    tempArr.push(<p>{argument} does not exist<br/>⠀</p>);
                        }
                    }
                    
                }
                break;
            }

            default:
                tempArr.push(<p>{parseString()} is not a recognized command</p>);
        tempArr.push(<p>Type "help" for list of commands<br/>⠀</p>);                
                break;
        }

        setContent({arr:tempArr});
    }

    var executed = false;
if (!executed) {
window.addEventListener("load", function(){
console.clear();

const inputContainerEl = document.querySelector(".input-container");
const textInputEl = document.querySelector("input#text");
const suggestionEl = document.querySelector(".suggestion-container");
const textBar = document.querySelector(".command-line");
const svgTabIcon = document.querySelector(".icon.tab-key");
const svgEnterIcon = document.querySelector(".icon.enter-key");


const ENTER_KEYCODE = 13;
const TAB_KEYCODE = 9;
const BACKSPACE_KEYCODE = 8;
const UP_ARROW_KEYCODE = 38;
const DOWN_ARROW_KEYCODE = 40;

let wordsArray = [
    "[Kagha@ArchBios~]$ ./AboutMe",
    "[Kagha@ArchBios~]$ ./Experience",
    "[Kagha@ArchBios~]$ ./Work",
    "[Kagha@ArchBios~]$ ./Skills",
    "[Kagha@ArchBios~]$ ./ContactMe",
    "[Kagha@ArchBios~]$ exec",
    "[Kagha@ArchBios~]$ exec AboutMe",
    "[Kagha@ArchBios~]$ exec Experience",
    "[Kagha@ArchBios~]$ exec Work",
    "[Kagha@ArchBios~]$ exec Skills",
    "[Kagha@ArchBios~]$ exec ContactMe",
    "[Kagha@ArchBios~]$ pkill",
    "[Kagha@ArchBios~]$ pkill AboutMe",
    "[Kagha@ArchBios~]$ pkill Experience",
    "[Kagha@ArchBios~]$ pkill Work",
    "[Kagha@ArchBios~]$ pkill Skills",
    "[Kagha@ArchBios~]$ pkill ContactMe",
   "[Kagha@ArchBios~]$ Download_CV_FR",
   "[Kagha@ArchBios~]$ Download_CV_ENG",
   "[Kagha@ArchBios~]$ exec Download_CV_FR",
   "[Kagha@ArchBios~]$ exec Download_CV_ENG",
    ];
	let suggestedWord = "";
	let suggestedWordsArray = [];
	let currentWordIndex = 0;
	let insertText = false;

	textInputEl.addEventListener("input", e => {
	    if (e.data !== " ") {
		insertText = true;
	    }
	    if (insertText === false) {
		textInputEl.value = "";
	    }

	    let inputValue = e.target.value;
	    suggestedWordsArray = filterArray(wordsArray, inputValue);
	    suggestedWord = suggestedWordsArray[0];

	    if (suggestedWord !== undefined  ) {
		suggestionEl.innerHTML = suggestedWord;
	    }

	    if (inputValue.length === 19 || suggestedWordsArray.length === 0) {
		suggestionEl.innerHTML = "";
	    }

	    if (suggestedWordsArray.length !== 19) {
		svgTabIcon.classList.remove("hidden");
		svgEnterIcon.classList.add("hidden");
	    } else {
		svgTabIcon.classList.add("hidden");
		svgEnterIcon.classList.remove("hidden");
	    }

	    if (inputValue.length === 19 || inputValue === suggestedWord) {
		svgTabIcon.classList.add("hidden");
		svgEnterIcon.classList.add("hidden");
	    }

	    if (textInputEl.value.length === 19) {
		insertText = false;
	    }

	});



	textInputEl.addEventListener("keydown", e => {

	    if (e.keyCode === ENTER_KEYCODE) {
		if (textInputEl.value.length === 19) return;
		let inputValue = textInputEl.value;
		let words = inputValue.split(" ");
		for (let i in words) {
		    if (words[i].length !== 0) {
			wordsArray.push(words[i]);
			textInputEl.value = "";
			suggestionEl.innerHTML = "";
		    }
		}
		wordsArray = removeDuplicatesFromArray(wordsArray);
		inputContainerEl.classList.add("animate");
		svgTabIcon.classList.add("hidden");
		svgEnterIcon.classList.add("hidden");
		removeClassAfterAnimationCompletes(inputContainerEl, "animate");
	    }

	    if (textInputEl.value.length !== 0) {
		if (e.keyCode === UP_ARROW_KEYCODE) {
		    if (currentWordIndex === 0) return;
		    currentWordIndex--;
		    suggestionEl.innerHTML = suggestedWordsArray[currentWordIndex];
		    if(suggestionEl.innerHTML==="undefined"){
			suggestionEl.innerHTML="";
		    }
		}

		if (e.keyCode === DOWN_ARROW_KEYCODE) {
		    if (currentWordIndex === suggestedWordsArray.length - 1) return;
		    currentWordIndex++;
		    suggestionEl.innerHTML = suggestedWordsArray[currentWordIndex];
		    if(suggestionEl.innerHTML==="undefined"){
			suggestionEl.innerHTML="";
		    }
		}

		if (e.keyCode === BACKSPACE_KEYCODE) {
		    currentWordIndex = 0;
		    //document.getElementById('text').innerHTML='Some new text.';
		    //console.log(suggestionEl.innerHTML)
		    //alert("ok")
		}
	    }

	    if (suggestedWord !== undefined && suggestedWord !== "") {
		if (e.keyCode === TAB_KEYCODE) {
		    setTerLine({Value:("[Kagha@ArchBios~]$ "  + parseString(e.target.value)),blink:true});
		    e.preventDefault();
		    textInputEl.value = suggestedWordsArray[currentWordIndex];
		    svgTabIcon.classList.add("hidden");
		    svgEnterIcon.classList.add("hidden");
		}
	    }
	});



	removeClassAfterAnimationCompletes(inputContainerEl, "animate");

	function removeClassAfterAnimationCompletes(el, className) {
	    let elStyles = window.getComputedStyle(inputContainerEl);
	    setTimeout(function() {
		el.classList.remove(className);
	    }, +elStyles.animationDuration.replace("s", "") * 1000);
	}

	function filterArray(array, item, reverse = false) {
	    if (reverse) {
		return array
		    .filter(word => compareTwoStrings(word, item))
		// .sort((a, b) => a.length - b.length);
	    } else {
		return array
		    .filter(word => compareTwoStrings(word, item))
		//.sort((a, b) => b.length - a.length);
	    }
	}

	function removeDuplicatesFromArray(array) {
	    return [...new Set(array.map(i => i))];
	}

	function compareTwoStrings(string, subString) {
	    let temp = string.split("", subString.length).join("");
	    if (subString === temp) return subString;
	}


        var c = document.getElementById("c");
        var ctx = c.getContext("2d");

        //making the canvas full screen
        c.height = window.innerHeight;
        c.width = window.innerWidth;

        //chinese characters - taken from the unicode charset
        var matrix = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ123456789@#$%^&*()*&^%+-/~{[|`]}";
        //converting the string into an array of single characters
        matrix = matrix.split("");

        var font_size = 10;
        var columns = c.width/font_size; //number of columns for the rain
        //an array of drops - one per column
        var drops = [];
        //x below is the x coordinate
        //1 = y co-ordinate of the drop(same for every drop initially)
        for(var x = 0; x < columns; x++)
            drops[x] = 1;

        //drawing the characters
        function draw()
        {
            //Black BG for the canvas
            //translucent BG to show trail
            ctx.fillStyle = "rgba(0, 0, 0, 0.04)";
            ctx.fillRect(0, 0, c.width, c.height);

            ctx.fillStyle = "#f4427d";//green text
            ctx.font = font_size + "px arial";
            //looping over drops
            for(var i = 0; i < drops.length; i++)
            {
                //a random chinese character to print
                var text = matrix[Math.floor(Math.random()*matrix.length)];
                //x = i*font_size, y = value of drops[i]*font_size
                ctx.fillText(text, i*font_size, drops[i]*font_size);

                //sending the drop back to the top randomly after it has crossed the screen
                //adding a randomness to the reset to make the drops scattered on the Y axis
                if(drops[i]*font_size > c.height && Math.random() > 0.975)
                    drops[i] = 0;

                //incrementing Y coordinate
                drops[i]++;
            }
        }

        setInterval(draw, 35);
});
}
    





    return (

        <div className={props.display + " main"}>

    {allTerminalText}



	    <div className="app">
    <div className="input-container">
	<form onSubmit={updateContent}>
	    <input type="text" className="command-line" autoFocus  id="text" autocomplete="off" spellCheck="false" value={getTerLine.Value} onChange={updateTerminalLine}  />
	</form>
			<span className="suggestion-container" id="sug"></span>
			<div className="icons-container">
			    <div className="icon tab-key hidden">
				<svg>
				    <use xmlns="#tab-key"></use>
				</svg>
	       </div>
			    <div className="icon enter-key hidden">
				<svg>
				    <use xmlns="#enter-key"></use>
				</svg>
			    </div>
			</div>
    </div>
	    </div>
	    </div>
    );

})
export default Terminal;

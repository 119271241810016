import React from 'react';
import './About.css';
import '../../Containers/Terminal/Terminal.css'

const About = () =>{
    return(
    <section className = "main section">
        <div className="about">

                <div  className="header">
                    <h1>About Me</h1>
		    
                </div>
	    <div>
<p class="ab"><br/>Hi, I'm <u><a className="sc"href="https://www.linkedin.com/in/ghassen-el-kamel-01b329176/" target="_blank" rel="noreferrer">Ghassen</a></u>! Welcome to my site. I'm a fifth-year exchange international student at <u><a className="sc"href="https://ensim.univ-lemans.fr/fr/index.html" target="_blank" rel="noreferrer">ENSIM Le Mans</a></u>, currently doing my internship at <u><a className="sc"href="https://www.bookbeo.com/" target="_blank" rel="noreferrer">Bookbeo</a></u> in Rennes as a full-stack mobile developer using React Native and AWS. I'm passionate about coding and creating technology to elevate people. I enjoy making open-source more approachable and building a strong community around it.
Currently, I'm diving deeper into the world of IoT & AI, constantly learning and exploring new things to improve my skills, find innovative solutions, and build exceptional projects.</p>

            <p class="ab"><br/>Here are a few technologies I've worked with recently: <br/>⠀</p>
                <ol className="technologies">
                    <li>HTML & CSS & JavaScript</li>
                    <li>Python</li>
                    <li>React Native</li>
                    <li>C/C++</li>
                    <li>Java</li>
                    <li>Linux</li>
                </ol>
	    </div>

            <div className="avatar"></div>
        </div>
    </section>
    );
}

export default About;

import React, {useState} from 'react';
import './Experience.css';
import '../../Containers/Terminal/Terminal.css'

const Experience = () =>{
    let [curJob, selectJob] = useState(0);



    let experience = 
    [["Full Stack Mobile Developer", (
        <div className="individualExperience">
          <h3>Full Stack Mobile Developer<span className="yellow"> @bookBeo</span></h3>
          <p>Mar 2023 - Present · 3 months</p>
          <p>Rennes, Brittany, France · On-site</p>
          <p>
            As a Full Stack Mobile Developer at bookBeo, I am currently involved in an exciting internship where I am contributing to the development of a mobile application. This opportunity allows me to showcase my skills in full stack development and work with cutting-edge technologies in the field.
          </p>
          <p>
            As part of my work, I also have the opportunity to explore and integrate technologies like Amazon Web Services (AWS) to enhance the scalability and performance of the application.
          </p>
          <div class="om svg-wrapper">
            <svg height="508" width="508" xmlns="http://www.w3.org/2000/svg">
              <rect class="shape1" height="508" width="508" />
              <rect class="shape2" height="508" width="508" />
            </svg>
            <img src={require('../../Resources/p7.jpg').default} height={500} width={500} />
          </div>
        </div>
      )],
        ["Security Project Team Member", (
        <div className="individualExperience">
          <h3>Security Project Team Member<span className="yellow"> @STMicroelectronics</span></h3>
          <p>Sep 2022 - Mar 2023 · 7 months</p>
          <p>Le Mans, Pays de la Loire, France · On-site</p>
          <p>
            As a Security Project Team Member at STMicroelectronics, I had the opportunity to work on an exciting study project focused on the study and implementation of the PSA Cryptography API Standard and its specifications. This project involved diving deep into the world of cryptography and exploring the ARM Cortex-M platform.
          </p>
          <p>
            The project required strong programming skills, and I utilized my expertise in C programming to develop efficient and secure embedded software. I also leveraged Linux tools and Git for version control and collaboration with the team.
          </p>
          <div class="om svg-wrapper">
            <svg height="508" width="508" xmlns="http://www.w3.org/2000/svg">
              <rect class="shape1" height="508" width="508" />
              <rect class="shape2" height="508" width="508" />
            </svg>
            <img src={require('../../Resources/p6.jpg').default} height={500} width={500} />
          </div>
        </div>
      )],        
        ["Mobile Developer Trainee", (
        <div className="individualExperience">
          <h3>Mobile Developer Trainee<span className="yellow"> @Falcon Inspection & Services</span></h3>
          <p>Jun 2022 - Aug 2022 · 3 months</p>
          <p>Tunis,Tunisia . On-site</p>
          <p>
            The project involved developing a mobile application that allows the user to verify and record data from four different tests: Resistance Test, Thermal Stabilization Test, Air Presence Test, and Waterproof Test. Each test has its own attributes and mathematical equations.
          </p>
          <p>
            <strong>FrontEnd:</strong> Flutter<br />
            <strong>Backend:</strong> Node.js
          </p>
          <p>
            Skills: Flutter · Docker Products · Git · Automation · Node.js · DevOps
          </p>
          <div class="om svg-wrapper">
		    <svg height="508" width="508" xmlns="http://www.w3.org/2000/svg">
			<rect class="shape1" height="508" width="508" />
			<rect class="shape2" height="508" width="508" />
		    </svg>

		<img src={require('../../Resources/p5.jpg').default}  height={500} width={500}/>
	    </div>
        </div>
      )],      
    ["DigiArtLivingLab",(
        <div className="individualExperience">
            <h3>Monitoring And Storage of Meteorological Parameters Of A Given Area<span className="yellow"> @DALL</span></h3>
            <p> 9 July 2021 - 23 August 2021</p>
            <p>Tunis,Tunisia . On-site</p>
            <ul className="jobDescription">
                <li>Experience in creating simple automation and computational programs utilizing Java</li>
                <li>Created  a server side code to automate the Storage of data </li>
                <li>Used open weather api and learned Json data parsing </li>
		<li>Created a user friendly interface using Javafx and Swing  </li>

            </ul>
	    <div class="om svg-wrapper">
		    <svg height="508" width="508" xmlns="http://www.w3.org/2000/svg">
			<rect class="shape1" height="508" width="508" />
			<rect class="shape2" height="508" width="508" />
		    </svg>

		<img src={require('../../Resources/p1.jpg').default}  height={500} width={500}/>
	    </div>
        </div>

    )],
    ["SAGEMCOM",(
        <div className="individualExperience">
            <h3>Verify The Existence Of Electronic Components And Validate Cards<span className="yellow"> @SAGEMCOM</span></h3>
            <p>Feburary 2020 - May 2020 </p>
            <p>Tunis,Tunisia . On-site</p>
            <ul className="jobDescription">
                <li>learned to use LabView and created a program using its libraries that allows the selection and detection of components</li>
                <li>Created and trained a convolutional neural network model capable of detecting several components</li>
                <li>Converted the model to TensorFlow lite and used it on a raspberry pi </li>
		<li>Created a camera holder 3D model using SolidWorks</li>
            </ul>
	    <div class="om svg-wrapper">
		    <svg height="508" width="508" xmlns="http://www.w3.org/2000/svg">
			<rect class="shape1" height="508" width="508" />
			<rect class="shape2" height="508" width="508" />
		    </svg>

	<a href="https://drive.google.com/file/d/1HEhe-tPGshCTeTujwacxx_Wc-qHJIF3j/view" target="_blank"><img src={require('../../Resources/p2.jpg').default}  height={500} width={500}/></a>
	    </div>
        </div>
    )],
    ["DigiArtLivingLab",(
        <div className="individualExperience">
            <h3>Build A Station For Humidity & Temperature Observation , A Scent Diffuser And Their Own Android Apps<span className="yellow"> @DALL</span></h3>
            <p> January 2019 - February 2019 </p>
            <p>Tunis,Tunisia . On-site</p>
            <ul className="jobDescription">
                <li>learned more about Arduino, used its libraries and coded the data transmission (I used Bluetooth) </li>
                <li>built both of the station and the Scent Difusser using the tools I had</li>
                <li>Created both apps using MIT App Inventor because of the time limitation </li>
		<li>linked the app to Firebase and stored the data in real time </li>
            </ul>
	    <div class="om svg-wrapper">
		    <svg height="508" width="508" xmlns="http://www.w3.org/2000/svg">
			<rect class="shape1" height="508" width="508" />
			<rect class="shape2" height="508" width="508" />
		    </svg>

		<img src={require('../../Resources/p3.jpg').default}  height={500} width={500}/>
	    </div>
        </div>
    )],
    ["DigiArtLivingLab",(
        <div className="individualExperience">
            <h3>Made An Educational-fun Game That Makes Kids Learn Directions <span className="yellow"> @DALL</span></h3>
            <p> January 2018 - February 2018</p>
            <p>Nabeul,Tunisia . On-site</p>
            <ul className="jobDescription">
                <li>learned about unity game engine and assets importation </li>
                <li>Discovered C++ and coded a part of the game using it</li>
                <li>Worked inside a team and Discovered the importance of team work</li>
		<li>created the Maze map and tried to grab kids attention with the adventurous theme and music</li>
            </ul>
	    <div class="om svg-wrapper">
		    <svg height="508" width="508" xmlns="http://www.w3.org/2000/svg">
			<rect class="shape1" height="508" width="508" />
			<rect class="shape2" height="508" width="508" />
		    </svg>

		<img src={require('../../Resources/p4.jpg').default}  height={500} width={500}/>
	    </div>
        </div>
    )]];


    let jobSelector = experience.map((job,key) => {
        let result = <li onClick={()=>{console.log(key);selectJob(key)}} key={key}>{job[0]}</li>;
        if(key === curJob)
            result =  <li onClick={()=>{selectJob(key)}} className = "jobSelected" key={key}>{job[0]}</li>
        return result
    })
    return(
    <div className = "experience main">
        <div>
            <div className="container">
                <span className="title">
                    <h1>My Internships</h1>
                </span>
                <ul className="jobSelector">
                    {jobSelector}
                </ul>
                {experience[curJob][1]}
            </div>
        </div>
    </div>
    );
}
export default Experience;

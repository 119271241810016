import React from 'react';
import './ContactMe.css';
import '../../Containers/Terminal/Terminal.css';
import emailjs from 'emailjs-com';

const ContactMe = () => {
  const submit = (e) => {
    e.preventDefault();
    let email = document.getElementById("email").value;
    let subject = document.getElementById("subject").value;
    let message = document.getElementById("content").value;

    let templateParams = {
      from_name: email,
      to_name: 'kagha',
      subject: subject,
      message: message,
    };

    if (email !== "" && subject !== "" && message !== "") {
      emailjs.send('service_d83m7mr', 'template_fqjqfgy', templateParams, '2K-cKu-63ycTZGWfw')
        .then((result) => {
          alert("Email Sent Successfully");
          console.log(result.text);
          document.getElementById("email").value = "";
          document.getElementById("subject").value = "";
          document.getElementById("content").value = "";
        })
        .catch((error) => {
          alert("Email Not Sent");
          console.log(error.text);
        });
    } else {
      alert("Please fill in all fields");
    }
  };

  return (
    <div className="main">
      <form onSubmit={submit} className="contactForm">
        <h3>Contact Me</h3>
        <p>If you have a question or simply want to say hello.</p>
        <input className="input" id="email" type="email" placeholder="Your Email" />
        <input className="input" id="subject" type="text" placeholder="What You Want to Talk About" />
        <textarea id="content" />
        <input type="submit" className="Submit" value="Submit" />
      </form>
    </div>
  );
};

export default ContactMe;
